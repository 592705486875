import React from "react";
import "./ComingSoon.css";
import companyLogo from "../../../assets/logo2.png";
import { FacebookRounded, LinkedIn } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - ECS</title>
      </Helmet>
      <div className="bg"></div>
      <h1 className="keyboard" data-text="Coming Soon.">
        <div>
          <span className="key">C</span>
          <span className="key">O</span>
          <span className="key">M</span>
          <span className="key">I</span>
          <span className="key">N</span>
          <span className="key">G</span>
        </div>
        <div>
          <span className="key">S</span>
          <span className="key">O</span>
          <span className="key">O</span>
          <span className="key">N</span>
          <span className="key">!</span>
        </div>
      </h1>
      <div className="fg"></div>
      <div className="page-footer">
        <img src={companyLogo} alt="logo" />
        <div className="footer-icons">
          <a
            href="https://www.facebook.com/profile.php?id=100063478676760&mibextid=9R9pXO"
            target="_blanck"
          >
            <FacebookRounded style={{ color: "#fff" }} />
          </a>
          <a href="https://www.linkedin.com/company/ecsegypt/" target="_blanck">
            <LinkedIn style={{ color: "#fff" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
