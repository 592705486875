import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { Box, CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import RouterClass from "./core/routes/router";
import LoadingOverlay from "./core/components/loading/OverlapLoading";
import { LoadingProvider } from "./core/services/loading-service";
import { theme } from "./core/theme/theme";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <ThemeProvider theme={theme()}>
      {/* <CssBaseline /> */}
      {/* <LoadingProvider> */}
      <Suspense fallback={<LoadingOverlay/>}>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <Router>
          <Routes>{RouterClass.getRoutes()}</Routes>
        </Router>
      )}
      </Suspense>
      {/* </LoadingProvider> */}
    </ThemeProvider>
  );
}

export default App;
